const sortOptions = [
  {name: 'aaa', display: 'AAA'},
  {name: 'bbb', display: 'BBB'},
  {name: 'ccc', display: 'CCC'},
];

function getSortOptions() {
  return sortOptions;
}

export {getSortOptions};
